<template >
    <div>
        <div class="app-modal__box">
            <div class="app-modal__in">
                <div
                    class="app-modal__header d-flex f-between p-5 m-title-modal"
                >
                    <p class="large--title m-0">
                        {{
                            $t("message.edit_m", {
                                m: $t("message.department"),
                            })
                        }}
                    </p>
                    <div>
                        <crm-store-update-close
                            :permission="$options.name"
                            :button_type="'store'"
                            :loading="loadingButton"
                            @c-submit="submit(true)"
                            @c-close="close()"
                        ></crm-store-update-close>
                    </div>
                </div>
            </div>
            <!-- app-modal__header end -->

            <div class="app-modal__body p-5 pb-0" v-loading="loadingData">
                <div class="timeline-items__right rounded-sm w-100 p-4">
                    <el-form ref="form" :model="form" :rules="rules">
                        <el-row :gutter="20">
									<el-col :span="24">
										<el-form-item
											:label="$t('message.name')"
											class="label_mini"
											prop="name"
										>
											<el-input
													:placeholder="$t('message.name')"
													v-model="form.name"
													size="medium"
											></el-input>
										</el-form-item>
									</el-col>
									<el-col :span="24">
										<el-form-item
											:label="$t('message.filial')"
											class="label_mini"
											prop="filial_id"
										>
											<select-filial
											:id="form.filial_id"
											v-model="form.filial_id"
											>
											</select-filial>
										</el-form-item>
									</el-col>
                        </el-row>
                    </el-form>
                </div>
            </div>
            <!-- end app-modal__body -->
        </div>
    </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import drawer from "@/utils/mixins/drawer";
import form from "@/utils/mixins/form";
import show from "@/utils/mixins/show";
import department from "@/utils/mixins/models/department";

export default {
	mixins: [form, drawer, show, department],
	name: "departments",
	methods: {
		...mapActions({
			save: "departments/update",
			show: "departments/show",
		}),
	},
};
</script>
