import { mapGetters, mapActions } from "vuex";
import SelectFilial from '@/components/inventory/select-filial';

export default {
	components: {SelectFilial},
	computed: {
		...mapGetters({
			rules: "departments/rules",
			model: "departments/model",
			columns: "departments/columns",
		}),
  },
   methods: {
		submit(close = true) {
			this.$refs["form"].validate((valid) => {
				if (valid) {
					this.loadingButton = true;
					this.save(this.form)
						.then((res) => {
							this.loadingButton = false;
							this.$alert(res);
							this.parent().listChanged();
							if (close) this.close();
						})
						.catch((err) => {
							this.loadingButton = false;
							this.$alert(err);
						});
				}
			});
		},
		afterLeave(){
			this.$store.commit('departments/EMPTY_MODEL');
		}
  },
}